import React from 'react'
import Footer from '../components/ui/Footer'
import data from '../venue_info.json'
import Venues from '../components/giftcards/Venues'
import Header from '../components/SEO/Header'
import Nav from '../components/ui/Nav'
import { Link } from 'react-router-dom'


const GiftCards = () => {
    const hotels = data.filter(venue => venue.category === "hotels")
    const dinings = data.filter(venue => venue.category === "dinings")
    const bars = data.filter(venue => venue.category === "bars")
    const liquor_stores = data.filter(venue => venue.category === "liquor stores")
    const golf = data.filter(venue => venue.category === "golf")
    const brewing = data.filter(venue => venue.category === "brewing")

  return (
    <div className='overflow-x-hidden'>
    <Header title="GiftCards" />
    <div className=' text-th-gray'>
        <Nav />
        <div className='pt-20 lg:pt-36 mb-12 lg:mb-24 w-screen'>
            <h1 className='font-th-primary text-5xl lg:text-8xl text-center mx-auto'>Gift Cards</h1>
        </div>
        <div className='bg-gift-card-hero w-9/12 mx-auto h-[200px] lg:h-[500px] bg-contain bg-center bg-no-repeat'>
        </div>
        <p className='font-th-tertiary text-center w-10/12 lg:w-9/12 mx-auto mt-10'>Viaggio Hospitality Group gift cards are available year-round and are perfect for corporate give-aways, birthdays, anniversaries, gatherings (big or small), special occasions, or just because. Our gift cards are available for and redeemable at all of our establishments (does not include the brewery section of Howe Sound Brewing). Physical gift cards can be purchased in store.</p>
        <p className='font-th-tertiary text-center w-9/12 mx-auto mt-5'>For any large and corporate purchases, feel free to <a href="mailto:marketing@Viaggiohospitality.com" className='link font-th-secondary'>contact us</a>.</p>
          <div className='flex lg:flex-row flex-col justify-center items-center mt-10 lg:mt-20 mb-16 lg:mb-20 gap-7 lg:gap-10 text-xl'>
            <Link to='buy' className='bg-th-gray hover:bg-th-yellow text-th-white hover:text-th-gray rounded-lg py-3 px-4 lg:px-6 font-th-secondary text-sm lg:text-base hover:-translate-y-1 transition-all'>Buy an E-Gift Card</Link>
            <Link to='check' className='bg-th-gray hover:bg-th-yellow text-th-white hover:text-th-gray rounded-lg py-3 px-4 lg:px-6 font-th-secondary text-sm lg:text-base hover:-translate-y-1 transition-all'>Check Your Balance</Link>
            <Link to='reload' className='bg-th-gray hover:bg-th-yellow text-th-white hover:text-th-gray rounded-lg py-3 px-4 lg:px-6 font-th-secondary text-sm lg:text-base hover:-translate-y-1 transition-all'>Reload Your Card</Link>
        </div>
        <div className='bg-gift-card bg-no-repeat bg-center bg-contain lg:bg-contain p-3 lg:p-10'>
        <div className='w-full md:w-10/12 xl:8/12 h-full mx-auto flex flex-col items-stretch justify-around gap-12 lg:gap-16'>
           <Venues title={"Hotels"} venues={hotels} />
           <Venues  title={"Dining"} venues={dinings} />
           <Venues  title={"Bars"} venues={bars} />
           <Venues  title={"Brewing (Restaurant/Pub only) "} venues={brewing} />
           <Venues  title={"Liquor Stores"} venues={liquor_stores} />
           <Venues  title={"Golf"} venues={golf} />
            </div>
        </div>
        <div className='mt-28'>
        <Footer />
        </div>
    </div>
    </div>
    
  )
}

export default GiftCards
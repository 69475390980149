import React from 'react'

const Title = ({title}) => {
  return (
    <div className='pt-20 lg:pt-36 mb-12 lg:mb-24 w-screen'>
            <h1 className='font-th-primary text-5xl lg:text-8xl text-center mx-auto'>{title}</h1>
        </div>
  )
}

export default Title
import React from 'react'

const Aboutus = () => {
  return (
    <div id="about" className='w-11/12 2xl:w-10/12 mx-auto text-th-gray my-20 2xl:my-28'>
        <div className='flex flex-col lg:flex-row justify-center items-center gap-5 2xl:gap-20'>
        <div className='bg-about bg-no-repeat bg-center lg:bg-left bg-contain'>
        <h3 className='font-th-secondary text-xl xl:text-3xl pl-0 lg:pl-2 mb-4 lg:mb-8 text-center lg:text-left'>About Us</h3>
        <h2 className='font-th-primary text-4xl lg:text-5xl 2xl:text-7xl tracking-wide pl-0 lg:pl-2 text-center lg:text-left'>
          Viaggio<br/>
          Hospitality<br /> 
          Group</h2>
        </div>
        <p className='font-th-tertiary leading-relaxed text-base text-center lg:text-left pt-0 xl:pt-16'>
        Viaggio Hospitality Group is one of British Columbia's largest hospitality companies and is a leader in providing outstanding food and beverage experiences. We are a growing number of establishments, each with its own unique concept, Viaggio Hospitality Group's mission is to provide a unified experience of exceptional service, quality, offerings and general hospitality. 
        <br />
        <br/>
        There is something to offer for every type of guest; whether you're looking for an upscale dining experience, a comfortable place to rest, a liquor store with impeccable selection, a beautiful course to swing some golf clubs, a lively night club or an exciting bar to catch the game, Viaggio Hospitality Group is yours to explore, enjoy and create lasting memories. Some notable establishments are the Downtown Moda Hotel, Ancora Waterfront Dining & Patio, Howe Sound Brewing, Viti Wine & Lager and Birchbank Golf Course. 
        </p>
        </div>
    </div>
  )
}

export default Aboutus
import React from 'react'
import Card from '../components/VenuePage/Card'
import data from '../venue_info.json'
import Title from '../components/VenuePage/Title'
import Header from '../components/SEO/Header'
import Nav from '../components/ui/Nav'
import Footer from '../components/ui/Footer'

const Brewing = () => {

    const brewing = data.filter(hotel => hotel.category === "brewing" )

    return (
      <div className='overflow-x-hidden'>
      <Header title="Brewing" />
      <div className='bg-th-white min-h-screen w-screen text-th-gray'>
      <Nav />
      <Title title="Brewing" />
      <div>
          {
              brewing.map((venue, i) => (
                  <Card key={i} venue={venue} i={i} />
              ))
          }
      </div>
  
      <Footer />
    </div>
    </div>
    )
  
}

export default Brewing
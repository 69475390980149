import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({title}) => {
  return (
    <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#3E3E3E" />
    <meta
      name="description"
      content="We have developed into one of the most prosperous hospitality businesses in the Greater Vancouver Area. We are passionate about encouraging internal development and we take great pride in working with a dynamic atmosphere that values teamwork."
    />
    <link rel="apple-touch-icon" href="/logo192.png" />
    <link rel="manifest" href="/manifest.json" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap" rel="stylesheet" />
    <link href="https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css" rel="stylesheet" />
  
    <title>Viaggio Hospitality Group {title && (" | " + title)}</title>
  </Helmet>
  )
}

export default Header
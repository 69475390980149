import React from 'react'
import Hotel from './category/Hotel'
import Dinings from './category/Dinings'
import Bars from './category/Bars'
import Golf from './category/Golf'
import LiquorStores from './category/LiquorStores'
import Brewing from './category/Brewing'

const Venues = () => {
  return (
    <div className='bg-th-white w-screen md:w-11/12 lg:w-10/12 mx-auto h-auto overflow-hidden pt-6 md:pt-10 text-th-gray'>
        <h1 className='font-th-primary text-5xl lg:text-7xl py-5 text-center md:text-left text-th-gray'>Our Venues</h1>
       <div className='flex flex-col items-center justify-center lg:flex-row gap-2 md:gap-5 xl:gap-10 mt-5 xl:mt-0'>
       <Hotel />
       <Dinings/>
       <Bars />
       </div>
       <div className='flex flex-col items-center justify-center lg:flex-row gap-2 md:gap-5 xl:gap-10 mt-2 md:mt-5 xl:mt-10'>
        <Golf />
        <LiquorStores />
        <Brewing />
       </div>
    </div>
  )
}

export default Venues
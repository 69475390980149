import './App.css';
import Aboutus from './components/home/Aboutus';
import Careers from './components/home/Careers';
import Hero from './components/home/Hero';
import MapBox from './components/home/Map';
import Venues from './components/home/Venues';
import Video from './components/home/Video';
import Header from './components/SEO/Header';
import Footer from './components/ui/Footer';
import Nav from './components/ui/Nav';

function App() {
  return (
    <>
    <Header title="Vancouver, BC" />
    <div className='overflow-x-hidden bg-white'>
    <Nav />
    <Hero />
    <Aboutus />
    <Venues />
    <MapBox />
    <Careers />
    <Footer />
    </div>
    </>
  );
}

export default App;

import React from 'react'
import Header from '../components/SEO/Header'
import Footer from '../components/ui/Footer'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { useState } from 'react'
import { TiLocationArrow, TiMail } from "react-icons/ti";
import Nav from '../components/ui/Nav';

const Contact = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [submited, setSubmited] = useState(false)
    const [submiting, setSubmiting] = useState(false)
    const [isError, setIsError] = useState(false)

    const onSubmit = data => {
        console.log(data)
        setSubmiting(true)
        axios.post('https://test.it-niradia.com/mail.php', {
            name: data.Name,
            email: data.Email,
            message: data.Message
        })
        .then(function (response) {
            setSubmited(true)
            setSubmiting(false)
            reset()
            setTimeout(() => {
            setSubmited(false)
            }, 8000);
          })
        .catch(function (error) {
            console.log(error);
            setSubmiting(false)
            setIsError(true)
          });
    }
  
  return (
    <div className='overflow-hidden'>
        <Header title="Contact" />
       <Nav />
        <div className='pt-24 lg:pt-48 pb-8 lg:pb-10 bg-th-light-yellow text-th-gray'>
          <div className='border bg-th-white rounded-md w-11/12 md:w-9/12 xl:w-1/2 mx-auto py-8 lg:py-16 flex justify-center items-center flex-col shadow-lg gap-1'>
          <h1 className='font-th-primary text-3xl lg:text-5xl pb-1'>Contact Us</h1>
          <div className='w-10 h-0.5 bg-th-yellow mb-2 lg:mb-5'></div>
          <p className='font-th-primary text-xl lg:text-3xl mt-2 mb-2 lg:mb-5 text-center'>Viaggio Hospitality Group Head Office</p>
          <a href='https://goo.gl/maps/Zs52yAZVdVB2TGoH8' target='_blank' rel="noreferrer" className='text-base lg:text-lg font-th-tertiary flex gap-1 items-center hover:-translate-y-0.5 transition-all'><TiLocationArrow className='fill-th-yellow text-2xl' />460 Fraserview Place, Delta</a>
          <a href='mailto:info@viaggiohospitality.com' className='text-base lg:text-lg font-th-tertiary flex gap-1 items-center hover:-translate-y-0.5 transition-all'><TiMail className='fill-th-yellow text-2xl' />info@viaggiohospitality.com</a>
          </div>
        </div>
        <div className='my-10 lg:my-20 w-11/12 md:w-9/12 xl:w-1/2 mx-auto font-th-tertiary'>
            {submited && (
                <div className="alert bg-th-yellow text-th-white flex justify-center text-base lg:text-lg font-th-secondary border shadow-lg mb-5">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  <span>Thank you!</span>
                  <span>Your submission has been sent.</span>
                </div>
              </div>
            )}
            {submiting && (
                <div className="alert bg-th-yellow text-th-white flex justify-center text-base lg:text-lg font-th-secondary border shadow-lg mb-5">
                <div>
                <svg aria-hidden="true" className="mr-2 w-8 h-8 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span>Sending...</span>
                </div>
              </div>
            )}
            {isError && (
                <div className="alert bg-th-yellow text-th-white flex justify-center text-base lg:text-lg font-th-secondary border shadow-lg mb-5">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Sorry. Something wrong...</span>
                </div>
              </div>
            )}
    <form onSubmit={handleSubmit(onSubmit)} method="POST">
    <div className="mb-6">
      <label className="block text-gray-700 text-base mb-2 font-th-secondary tracking-wide">
        Name
      </label>
      {errors.Name && (
        <div className="alert rounded-none p-0 pb-1 bg-transparent">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-th-yellow flex-shrink-0 w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span className='text-sm text-th-yellow'>Name is required</span>
        </div>
      </div>
      )}
      <input {...register("Name",  { required: true })}  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline" type="text"  />
    </div>
    <div className="mb-6">
      <label className="block text-gray-700 text-base mb-2 font-th-secondary tracking-wide">
      Email Address
      </label>
      {errors.Email && (
        <div className="alert rounded-none p-0 pb-1 bg-transparent">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-th-yellow flex-shrink-0 w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span className='text-sm text-th-yellow'>Email Address is required</span>
        </div>
      </div>
      )}
      <input {...register("Email", { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline-none focus:shadow-outline" type="e-mail" />
    </div>
    <div className="mb-6">
      <label className="block text-gray-700 text-base mb-2 font-th-secondary tracking-wide">
        Message
      </label>
      {errors.Message && (
        <div className="alert rounded-none p-0 pb-1 bg-transparent">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-th-yellow flex-shrink-0 w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span className='text-sm text-th-yellow'>Message is required</span>
        </div>
      </div>
      )}
      <textarea rows='7' {...register("Message", { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline-none focus:shadow-outline" />
    </div>
        <div className="flex items-center justify-center">
        <input type="submit" className='font-th-primary cursor-pointer tracking-wider font-semibold px-5 py-3 hover:bg-th-yellow bg-th-gray rounded-lg text-th-white uppercase hover:-translate-y-1 transition-all' value={submiting ? "Sending...":'Submit'} />
    </div>
    </form>
        </div>
        <Footer />
    </div>
  )
}

export default Contact
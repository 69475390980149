import React from 'react'
import Card from '../components/VenuePage/Card'
import data from '../venue_info.json'
import Title from '../components/VenuePage/Title'
import Header from '../components/SEO/Header'
import Nav from '../components/ui/Nav'
import Footer from '../components/ui/Footer'

const Bars = () => {
  const bars = data.filter(hotel => hotel.category === "bars" )

  return (
    <div className='overflow-x-hidden'>
    <Header title="Bars" />
    <div className='bg-th-white min-h-screen w-screen text-th-gray'>
   <Nav />
      <Title  title="Bars" />
    <div>
        {
            bars.map((bar, i) => (
                <Card key={i} venue={bar} i={i} />
            ))
        }
    </div>
    <Footer />
</div>
</div>
  )}
export default Bars
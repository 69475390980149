import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "../../index.css";

const Hero = () => {
  return (
    <div className='mt-14 lg:mt-20 w-screen h-[70vh] md:h-[82vh]'>
    <Swiper
      navigation={true} 
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: true,
      }}
      speed={1200}
      loop={true}
      modules={[Navigation, Autoplay]}
  >
    <SwiperSlide>
      <div className='relative'>
      <img
       src="/assets/img/hero/01.jpg"
        className="block w-full h-[70vh] md:h-[82vh] object-center object-cover relative top-0 left-0"
        alt="Hero1"
      />
      <a href="https://ancoradining.com" className='absolute bottom-10 left-1/2 -translate-x-1/2 md:bottom-44 md:left-32 md:translate-x-0 fade_in cursor-pointer'>
        <img src="/assets/logos/gray/ancora.svg" alt="ancora_logo" className='w-[50vw] md:w-[30vw] xl:w-[450px] h-fit' />
      </a>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='relative'>
      <img
       src="/assets/img/hero/02.jpg"
        className="block w-full h-[70vh] md:h-[82vh] object-center object-cover relative top-0 left-0"
        alt="Hero2"
      />
      <a  href="https://cibotrattoria.com" className='absolute -bottom-2 left-1/2 -translate-x-1/2 md:bottom-24 md:left-32 md:translate-x-0 fade_in cursor-pointer'>
        <img src="/assets/logos/gray/cibo.svg" alt="ancora_logo" className='w-[40vw] md:w-[30vw] xl:w-[350px] h-fit' />
      </a>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='relative'>
      <div className="block w-full h-[70vh] md:h-[82vh] object-center object-cover bg-cover bg-no-repeat bg-center relative top-0 left-0 bg-hero_03"></div>
       <a href='https://metbargrill.com' className='absolute bottom-7 left-1/2 -translate-x-1/2 md:bottom-44 md:left-32 md:translate-x-0 fade_in cursor-pointer'>
        <img src="/assets/logos/met-logo.svg" alt="ancora_logo" 
        className='w-[40vw] md:w-[30vw] xl:w-[350px] h-fit' />
      </a>
      </div>
      </SwiperSlide>
  </Swiper> 
</div>
  )
}

export default Hero
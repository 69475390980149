import React, {useRef, useState} from 'react'
import Nav from '../components/ui/Nav'
import IframeResizer from 'iframe-resizer-react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/ui/Footer'

const GiftCardBuy = () => {
    const [loading, setLoading] = useState(true)
    const iframeRef = useRef(null);
    const navigate = useNavigate();
    return (
      <div className='w-screen'>
        <Nav />
            {
                loading &&
                <div role="status" className={`${loading ? 'opacity-1': 'opacity-0'} mt-20 lg:mt-40 flex justify-center items-center w-11/12 lg:w-9/12 mx-auto min-h-screen bg-gray-100 rounded-lg animate-pulse transition-all delay-300 duration-300`}>
                 <span className="sr-only">Loading...</span>
                </div>
              }
        <div className={`${loading ? 'opacity-0': 'opacity-1'} mt-20 lg:mt-40 mx-auto min-h-screen transition-all delay-300 duration-300`}>
            <IframeResizer
              scrolling
              forwardRef={iframeRef}
              heightCalculationMethod="lowestElement"
              widthCalculationMethod='rightMostElement'
              inPageLinks
              onLoad={() => setLoading(false)}
              src={ `https://viaggiohospitality.ackroo.net/en/gifts/new?iframe=true&v=4.1.1`}
              style={{ width: '1px', minWidth: '100%'}}
                />
        <div className={`${loading ? 'opacity-0': 'opacity-1'} w-10/12 flex justify-center items-center mx-auto mt-7 mb-20 transition-all delay-300 duration-300`}>
        <button onClick={() => navigate(-1)} className='py-2 px-5 font-th-secondary text-white bg-th-gray rounded-full text-sm'>BACK</button>
        </div>
        </div>
        <Footer />
      </div>
    )
}

export default GiftCardBuy
import React from 'react'

const Careers = () => {
  return (
    <div className=' text-th-gray bg-th-light-yellow mb-0 mt-10 lg:mt-40 py-10 lg:py-16'>
        <div className='w-11/12 lg:w-9/12 mx-auto text-center py-10'>
        <h2 className=' font-th-primary text-4xl lg:text-7xl mb-7'>Work With Us</h2>
        <p className=' font-th-tertiary text-base px-0 md:px-20 xl:px-40 leading-relaxed'>At Viaggio, maximizing your potential is our priority. We are always seeking the next hospitality leaders to work with our unique venues to become a part of creating lasting memories for our guests and partners. Viaggio is an equal opportunity employer that celebrates diversity and values work-life balance. We offer a professional work environment with a team that strives for excellence.</p>
        </div>
        <div className='flex justify-center items-center'>
        <a href='/careers' target='_blank' className='inline-block font-th-primary font-thin text-xl lg:text-2xl bg-th-gray text-th-white hover:text-th-gray hover:bg-th-yellow rounded-full px-7 py-2 transition-all'>
            Current Openings
        </a>
        </div>
    </div>
  )
}

export default Careers
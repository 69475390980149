import React from 'react'
import Card from '../components/VenuePage/Card'
import data from '../venue_info.json'
import Title from '../components/VenuePage/Title'
import Header from '../components/SEO/Header'
import Nav from '../components/ui/Nav'
import Footer from '../components/ui/Footer'

const LiquorStores = () => {
    const liquor_store = data.filter(hotel => hotel.category === "liquor stores" )

    return (
      <div className='overflow-x-hidden'>
      <Header title="Liquor Stores" />
      <div className='bg-th-white min-h-screen w-screen text-th-gray overflow-hidden'>
      <Nav />
     <Title title="Liquor Stores" />
      <div>
            <Card  venue={liquor_store[0]} i={1} />
            <Card  venue={liquor_store[5]} i={2} />
         
      </div>
  
      <Footer />
  </div>
  </div>
    )
}

export default LiquorStores
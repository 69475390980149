import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const LiquorStores = () => {
    const [isHover, setIsHover] = useState(false)
    
  return (
        <Link to='/liquor_stores' className={`relative bg-th-light-yellow flex w-full lg:w-1/3 h-[250px] lg:h-[450px] 2xl:h-[550px] cursor-pointer overflow-hidden`} onMouseEnter={()=> setIsHover(true)} onMouseOver={()=> setIsHover(true)} onMouseOut={() => setIsHover(false)} onMouseLeave={() => setIsHover(false)}>
        <div className={`${isHover ? ' bg-black' : 'bg-th-light-yellow flex flex-row-reverse lg:flex-col justify-between items-center'} w-full`}>
        <img src="/assets/img/liquorstore.jpg" alt="viti-img" className={`${isHover ? 'w-full opacity-70 fade_out h-full':'w-1/2 lg:w-full fade_in h-full lg:h-1/2 2xl:h-2/3'} object-cover`} />
        <div className={`${isHover ? 'absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 xl:top-1/3 xl:-translate-y-1/3 text-white flex flex-col items-center': 'flex flex-col justify-center items-center h-full lg:h-1/2 2xl:h-1/3 w-1/2 md:1-full gap-3 lg:gap-0'} transition delay-75 duration-500`}> 
            <h3 className='font-th-secondary text-xs md:text-sm xl:text-base 2xl:text-lg mb-3  whitespace-pre-wrap xl:whitespace-nowrap text-center'>Viti Wine and Lager  | The bottle Shop</h3>
            <h2 className={`tracking-normal font-th-primary text-3xl 2xl:text-5xl mb-0 md:mb-3 whitespace-pre-wrap xl:whitespace-nowrap text-center`}>liquor Stores</h2>
            <div className='flex items-center justify-center gap-4'>
            <p className='font-th-primary text-xl lg:text-2xl'>More</p>
            <div className={`${isHover ? 'arrow_animate' : ''} relative my-5`}>
            <svg height="8" viewBox="0 0 81 7" xmlns="http://www.w3.org/2000/svg" className={`${isHover ? 'fill-th-silver' :'fill-th-gray'} xl:w-full w-[50px]`}>
            <path d="M80.9334 3.5L75.946 0.613277L75.9208 6.38672L80.9334 3.5ZM0.931222 4H76.4312L76.4356 3H0.935599L0.931222 4Z"/>
            </svg>
            <div className={`${isHover ? ' border-white':'border-th-gray' } border rounded-full w-7 xl:w-10 h-7 xl:h-10 absolute -top-2.5 xl:-top-4 -right-2 md:-right-4`} ></div>
            </div>
            </div>
        </div>
       
        <div className={`${isHover ? 'right-0 opacity-100' : 'opacity-0 -right-96'} w-full hidden xl:flex flex-wrap absolute bottom-10 justify-center items-center transition-all delay-75 duration-700`}>
        <img src="/assets/logos/viti.png" alt="viti-logo" className='m-2 w-[100px] h-fit  max-h-[50px] object-contain' />
        <img src="/assets/logos/bottleshop.png" alt="bottleshop-logo" className='m-2 w-[100px] max-h-[80px] object-contain' />
        </div>
        </div>
    </Link>
  )
}

export default LiquorStores
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Bars = () => {
    const [isHover, setIsHover] = useState(false)
    
  return (
     <Link to='/bars' className={`relative bg-th-light-yellow flex w-full lg:w-1/3 h-[250px] lg:h-[450px] 2xl:h-[550px] cursor-pointer overflow-hidden`} onMouseEnter={()=> setIsHover(true)} onMouseOver={()=> setIsHover(true)} onMouseOut={() => setIsHover(false)} onMouseLeave={() => setIsHover(false)}>
     <div className={`${isHover ? ' bg-black' : ' bg-th-light-yellow flex flex-row-reverse lg:flex-col justify-between items-center'} w-full`}>
     <img src="/assets/img/bars.png" alt="bars-img" className={`${isHover ? 'w-full opacity-70 fade_out h-full object-cover scale-105 object-left-top ':'w-1/2 lg:w-full fade_in h-full lg:h-1/2 2xl:h-2/3'} object-cover`} />
     <div className={`${isHover ? 'absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 xl:top-1/3 xl:-translate-y-1/3 text-white flex flex-col items-center': 'flex flex-col justify-center items-center h-full lg:h-1/2 2xl:h-1/3 w-1/2 md:1-full gap-3 lg:gap-0'} transition delay-75 duration-500`}> 
         <h3 className='font-th-secondary text-xs md:text-base xl:text-base 2xl:text-lg mb-3 whitespace-nowrap'>Bars</h3>
         <h2 className={`tracking-normal font-th-primary text-4xl lg:text-5xl mb-0 md:mb-3`}>Bars</h2>
         <div className='flex items-center justify-center gap-4'>
         <p className='font-th-primary text-xl lg:text-2xl'>More</p>
         <div className={`${isHover ? 'arrow_animate' : ''} relative my-5`}>
         <svg height="8" viewBox="0 0 81 7" xmlns="http://www.w3.org/2000/svg" className={`${isHover ? 'fill-th-silver' :'fill-th-gray'} xl:w-full w-[50px]`}>
         <path d="M80.9334 3.5L75.946 0.613277L75.9208 6.38672L80.9334 3.5ZM0.931222 4H76.4312L76.4356 3H0.935599L0.931222 4Z"/>
         </svg>
         <div className={`${isHover ? ' border-white':'border-th-gray' } border rounded-full w-7 xl:w-10 h-7 xl:h-10 absolute -top-2.5 xl:-top-4 -right-2 md:-right-4`} ></div>
         </div>
         </div>
     </div>
     <div className={`${isHover ? 'right-0 opacity-100' : 'opacity-0 -right-96'} w-full hidden xl:flex flex-wrap absolute bottom-10 flex-col justify-center items-center transition-all delay-75 duration-700`}>
         <div className='flex items-center justify-around w-11/12 xl:w-10/12'>
         <img src="/assets/logos/uva.png" alt="uva-logo" className='m-2 w-[70px] 2xl:w-[85px] max-h-[70px] h-fit object-contain' />
        <img src="/assets/logos/tiki.svg" alt="tikibar-logo" className='m-2 w-[70px] 2xl:w-[85px] max-h-[80px] h-fit object-cover' />
        <img src="/assets/logos/met-logo.svg" alt="metbar-logo" className='m-2 w-[70px] 2xl:w-[85px] max-h-[80px] h-fit object-cover' />
         </div>
         <div className='flex items-center justify-around w-10/12 xl:w-9/12'>
        <img src="/assets/logos/youbou.svg" alt="youbou-logo" className='m-2 w-[70px] 2xl:w-[85px] max-h-[80px] h-fit object-cover' />
        <img src="/assets/logos/funky.svg" alt="funky-logo" className='m-2 w-[70px] 2xl:w-[85px] max-h-[80px] h-fit object-cover' />
       </div>
     </div>
     </div>
 </Link>
  )
}

export default Bars
import React from 'react'
import { BiRightArrowAlt } from "react-icons/bi";
import { useInView } from 'react-intersection-observer';
import { MdLocationOn } from "react-icons/md";


const Card = ({venue, i}) => {
  const { ref, inView } = useInView({
    threshold: 0
  });

  return (
    <div ref={ref} className={`border-t border-th-yellow py-5 lg:py-10 xl:p-5 h-fit w-screen overflow-hidden`}>
        <div className={`${inView ? 'opacity-100 translate-y-0' : ' opacity-5 translate-y-2'} delay-150 duration-700 transition-all w-full lg:w-11/12 xl:w-10/12 mx-auto flex justify-around items-center gap-1 lg:gap-10 ${i % 2 === 0 ? 'flex-col-reverse lg:flex-row-reverse' : 'flex-col-reverse lg:flex-row'}`}>
        <div className='w-11/12 lg:w-1/2 flex flex-col justify-evenly items-center px-0 xl:px-5'>
        <h1 className=' font-th-primary text-3xl lg:text-5xl my-1 lg:mt-0 mb-3 text-center'>{venue.name.includes('|') ? "Viti Wine and Lager" : venue.name}</h1>
        {venue.address && 
        Array.isArray(venue.address) ?
        <div className='mb-5 lg:mb-10'>
        {venue.address.map((e, i) => <a href={e.url} key={i} target="_blank" rel="noreferrer" className='text-sm lg:text-base mb-0.5 font-th-secondary flex items-center gap-1.5 flex-nowrap hover:-translate-y-0.5 transition-all'><MdLocationOn className='w-5 h-5' />{e.text}</a>)}
        </div>
        : venue.address && 
        <a href={venue.address.url} target="_blank" rel="noreferrer" className='text-sm lg:text-base font-th-secondary mb-5 lg:mb-10 flex items-center gap-1.5 flex-nowrap hover:-translate-y-0.5 transition-all'><MdLocationOn className='w-5 h-5' />{venue.address.text}</a>}
        <p className='mb-5 text-sm font-th-tertiary'>{venue.desc}</p>
        <a href={venue.url} className="mt-3 lg:mt-5 font-th-tertiary font-lg bg-white/40 px-10 py-2 rounded-full border border-th-gray text-th-gray flex items-center gap-1 hover:-translate-y-0.5 transition-all" target='_blank' rel="noreferrer">More<BiRightArrowAlt /></a>
        </div>
        <img src={venue.img} alt="venueimage" className='w-11/12 lg:w-[400px] xl:w-[600px] h-[250px] lg:h-[400px] xl:h-[500px] object-cover rounded-bl-[80px] lg:rounded-bl-[150px] rounded-tr-[80px] lg:rounded-tr-[150px]' />
        </div>
    </div>
  )
}

export default Card
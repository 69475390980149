import React from 'react'
import Card from '../components/VenuePage/Card'
import data from '../venue_info.json'
import Title from '../components/VenuePage/Title'
import Header from '../components/SEO/Header'
import Nav from '../components/ui/Nav'
import Footer from '../components/ui/Footer'

const Dinings = () => {
  const dinings = data.filter(hotel => hotel.category === "dinings" )

  return (
    <div className='overflow-x-hidden'>
    <Header title="Dining" />
    <div className='bg-th-white min-h-screen w-screen text-th-gray '>
        <Nav />
       
       <Title title="Dining" />
        <div>
            {
                dinings.map((dining, i) => (
                    <Card key={i} venue={dining} i={i} />
                ))
            }
        </div>
    
       <Footer />
    </div>
    </div>
  )
}

export default Dinings
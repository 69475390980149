import { useEffect, useMemo } from 'react';
import Map, {Marker, Popup} from 'react-map-gl';
import data from '../../venue_info'
import { useState } from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import mapboxgl from 'mapbox-gl';

const MapBox = () => {
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

    const [category, setCategopry] = useState('all')
    const [pins, setPins] = useState([...data])
    const [pinOpen, setPinOpen] = useState({data:null, open: false})

    useEffect(() => {
    if(category === 'all') {
        setPins([...data])
    }else {
     const filteredList = data.filter(el => el.category === category)
     setPins([...filteredList])
    }
    }, [category])

    const locationPins = useMemo(
        () => 
        pins.map((venue, i) => (
            <Marker key={i} onClick={(e) => {e.originalEvent.stopPropagation(); openPopUp(venue)}} longitude={venue.long} latitude={venue.lat} anchor="center" >
            <FaMapMarkerAlt size={30} className="text-th-yellow cursor-pointer stroke-th-gold stroke-2" />
            </Marker>
        )), [pins])

    const openPopUp = (venue) => {
        setPinOpen({data: venue, open: true})
    }

    return (
        <div className="my-20 lg:my-40 mx-auto overflow-hidden w-11/12 lg:w-10/12">
        <div className='flex justify-around flex-wrap md:flex-nowrap mx-auto w-10/12 lg:w-7/12 items-center font-th-primary text-sm'>
            <button className={`${category === 'all' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('all')}>All</button>
            <button className={`${category === 'hotels' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('hotels')}>Hotels</button>
            <button className={`${category === 'dinings' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('dinings')}>Dining</button>
            <button className={`${category === 'bars' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('bars')}>Bars</button>
            <button className={`${category === 'golf' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('golf')}>Golf</button>
            <button className={`${category === 'brewing' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('brewing')}>Brewing</button>
            <button className={`${category === 'liquor stores' ? 'bg-th-yellow': 'bg-transparent'} hover:bg-th-yellow py-1 px-2 transition-all rounded-lg`} onClick={() => setCategopry('liquor stores')}>Liquor Stores</button>
        </div>
        <div className='w-full h-[40vh] lg:h-[60vh] mt-5 md:mt-10'>
        <Map
        initialViewState={{
          longitude: -123.066666,
          latitude: 49.316666,
          zoom: 10
        }}
        mapStyle="mapbox://styles/mapbox/light-v10"
        onRender={(event) => event.target.resize()}
        mapboxAccessToken={process.env.REACT_APP_MAP}
      >
    {locationPins}
    {pinOpen.data && (
        <Popup longitude={Number(pinOpen.data.long)} latitude={Number(pinOpen.data.lat)}
        anchor="top"
        onClick={() => setPinOpen({data:null, open: false})}
        onClose={() => setPinOpen({data:null, open: false})}
        >
        <div className='px-2 text-center'>
        <div className='relative'>
        <img src={pinOpen.data.img} alt={pinOpen.data.name} className="w-full h-28 object-cover" />
        <div className='bg-th-yellow w-fit mx-auto rounded-full absolute top-1 left-1 flex justify-center items-center'>
        <p className='uppercase font-th-tertiary text-[10px] px-1 py-0.5'>
        {pinOpen.data.category}
        </p>
        </div>
        </div>
        <h3 className='font-th-tertiary py-5 text-sm leading-tight'>
        {pinOpen.data.name} 
        </h3>
        <a href={pinOpen.data.url} target="_blank" rel="noreferrer" className='rounded-full text-xs bg-th-gray font-th-secondary text-th-white px-5 py-1.5 uppercase'>Website</a>
        </div>
        </Popup>)
        }
      </Map>
      </div>
      </div>
    );
}


export default MapBox
import React from 'react'
import { Link } from 'react-router-dom'
import { TiLocationArrow, TiMail } from "react-icons/ti";
import { RiLinkedinFill } from "react-icons/ri";
import { FaPinterestP } from "react-icons/fa";
import { HashLink } from 'react-router-hash-link';


const Footer = () => {
  return (
    // <div className=' border-th-gray border-t overflow-hidden text-th-white font-th-tertiary'>
    //   <div className='mb-3 lg:mb-0 w-full lg:w-11/12 mx-auto flex items-center justify-around flex-col lg:flex-row'>
    //   <div className='flex flex-col w-full lg:w-1/4 items-center'>
    //   <img src="/assets/logos/viaggio-footer.svg" alt="viaggio-logo" className='h-28 w-32 md:w-60 md:h-60 xl:w-80 xl:h-80 object-contain' />
    //   </div>
    //   <div className='w-full lg:w-3/4'>
    //     <div className='flex items-center justify-around flex-col lg:flex-row text-sm font-th-tertiary'>
    //       <Link to="/hotels" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase">Hotels</Link>
    
    //       <Link to="/dining" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase">Dining</Link>
       
    //       <Link to="/bars" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase">Bars</Link>
      
    //       <Link to="/golf" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase">Golf</Link>
     
    //       <Link to="/brewing" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase ">Brewing</Link>
       
    //       <Link to="/liquor_stores" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase ">Liquor Stores</Link>
      
    //       <Link to="/giftcards" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase ">Gift Cards</Link>
          
    //       <Link to="/contact" className="block py-2 pr-4 pl-3 hover:-translate-y-0.5 transition-all uppercase ">Contact</Link>
    //       <div className='flex gap-8 mt-5 lg:mt-0'>
    //       <a href='https://www.pinterest.ca/viaggiohospitality/' target="_blank" rel="noreferrer"><RiPinterestLine className=' hover:fill-th-yellow w-8 h-8 transition-all' /></a>
    //       <a href='https://www.linkedin.com/company/viaggiohospitality/' target="_blank" rel="noreferrer"><RiLinkedinFill className=' hover:fill-th-yellow w-8 h-8 transition-all' /></a>
    //       </div>
    //     </div>
    //     <div className="divider"></div>
    //     <div className='flex items-center justify-around flex-col lg:flex-row text-sm lg:text-base gap-2 lg:gap-0 font-th-tertiary'>
    //     <a href='mailto:info@viaggiohospitality.com' className='text-base flex gap-1 items-center hover:-translate-y-0.5 transition-all'><TiMail className='fill-th-yellow text-2xl' />info@viaggiohospitality.com</a>
    //     <a href='https://goo.gl/maps/A5PPcPZukKjvGoEU7' target='_blank' rel="noreferrer" className='text-base flex gap-1 items-center hover:-translate-y-0.5 transition-all'><TiLocationArrow className='fill-th-yellow text-2xl' />460 Fraserview Place, Delta</a>
    //     </div>
    //   </div>
    //   </div>
    //   <div className='bg-th-gray text-th-white py-3 flex justify-center items-center flex-col mt-10 lg:mt-0'>
    //   <p className='font-th-tertiary text-xs text-center'>© 2021 Viaggio Hospitality Group. All rights reserved.</p>
    //   <p className='font-th-tertiary text-xs text-center'>Powered by Niradia Tech Shiho K. - Carlos D.</p>
    //   </div>
    // </div>
  <footer className='bg-th-gray'>
  <div className="w-11/12 max-w-[1860px] py-10 lg:pt-16 lg:pb-24 font-th-tertiary text-th-white flex flex-col lg:flex-row justify-between items-start mx-auto">
  <div className='h-full w-full lg:w-1/2 flex flex-col justify-around items-center lg:items-start'>
   <img src="/assets/logos/viaggio-footer.svg" alt="viaggio-logo" className='w-32 md:w-60 md:h-auto object-contain' />
     <a href='mailto:info@viaggiohospitality.com' className='mt-3 text-sm font-th-tertiary flex gap-2 items-center hover:-translate-y-0.5 transition-all'><TiMail className='fill-th-yellow text-2xl' />info@viaggiohospitality.com</a>
     <a href='https://goo.gl/maps/Zs52yAZVdVB2TGoH8' target='_blank' rel="noreferrer" className='text-sm font-th-tertiary flex gap-2 items-center hover:-translate-y-0.5 transition-all'><TiLocationArrow className='fill-th-yellow text-2xl' />460 Fraserview Place, Delta</a>
  </div> 
  <div className='w-full lg:w-1/2 h-full mt-5 lg:mt-0 flex lg:flex-row flex-col gap-5 lg:gap-0 justify-between items-center lg:items-start text-center lg:text-left font-th-tertiary text-sm'>
  <div>
    <span className='block w-20 lg:w-40 h-0.5 bg-th-yellow mb-2'></span>
    <span className="font-th-primary uppercase text-base text-th-white">Company</span> 
    <div className='flex flex-col gap-2 mt-3 lg:mt-5 font-thin'>
      <HashLink to="/#about" 
      scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
      className="block uppercase">About US</HashLink>   
      <Link to="/giftcards" className="block uppercase">Gift Cards</Link>      
      <a href='/careers' target="_blank" className="block uppercase">Careers</a>
      <Link to="/contact" className="block uppercase">Contact</Link>
    </div>
  </div> 
  <div className='hidden lg:block'>
    <span className='block w-40 h-0.5 bg-th-yellow mb-2'></span>
    <span className="font-th-primary uppercase  text-base text-th-white">Explore</span> 
    <div className='flex gap-2 lg:gap-5 flex-col lg:flex-row justify-center items-start mt-3 lg:mt-5'>
    <div className='flex flex-col gap-2 font-thin'>
    <Link to="/hotels" className="block uppercase">Hotels</Link>
    <Link to="/dining" className="block uppercase">Dining</Link>
    <Link to="/bars" className="block uppercase">Bars</Link>
    <Link to="/golf" className="block uppercase">Golf</Link>
    </div>
    <div className='flex flex-col gap-2 font-thin'>
    <Link to="/brewing" className="block uppercase">Brewing</Link>
    <Link to="/liquor_stores" className="block uppercase ">Liquor Stores</Link>
    </div>
    </div>
  </div> 
  <div>
    <span className='block w-20 lg:w-40 h-0.5 bg-th-yellow mb-2'></span>
    <span className="font-th-primary uppercase text-base text-th-white">Social</span> 
    <div className='flex gap-2 justify-start items-center mt-3 lg:mt-5'>
    <a href='https://www.pinterest.ca/viaggiohospitality/' target="_blank" rel="noreferrer" className='block border rounded-full p-1.5 hover:bg-th-yellow'><FaPinterestP className='fill-th-white w-5 h-5 transition-all' /></a>
    <a href='https://www.linkedin.com/company/viaggiohospitality/' target="_blank" rel="noreferrer" className='block border rounded-full p-1.5 hover:bg-th-yellow'><RiLinkedinFill className='fill-th-white w-5 h-5 transition-all' /></a>
    </div>
  </div>
 </div>
</div>
  <div className='w-11/12 mx-auto border-t-2 border-th-yellow bg-th-gray text-th-white py-3 flex justify-center items-center flex-col mt-5 lg:mt-0'>
      <p className='font-th-tertiary text-xs text-center'>© 2022 Viaggio Hospitality Group. All rights reserved.</p>
      <p className='font-th-tertiary text-xs text-center'>Powered by Niradia Tech Shiho K. - Carlos D.</p>
  </div>
</footer>
  )
}

export default Footer
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { RiMenu5Fill } from "react-icons/ri";

const Nav = () => {
const [open, setOpen] = useState(false)
const [prevScrollPos, setPrevScrollPos] = useState(0);
const [visible, setVisible] = useState(true)

const handleScroll = () => {
    const currentScrollPos = window.scrollY
    if(currentScrollPos === 0){
      setVisible(true)
    }else if(currentScrollPos > prevScrollPos && !open){
      setVisible(false)
    }else{
        setVisible(true)
    }
    setPrevScrollPos(currentScrollPos)
}

useEffect( () => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll)
})

  return ( 
    <nav className={`${visible ? 'visible' : ' hidden'} bg-white fixed z-50 top-0 w-screen left-1/2 -translate-x-1/2 xl:px-2 px-0 pt-3 pb-2 md:pt-2 xl:pt-3 md:pb-1 overflow-hidden transition-all duration-500`}>
    <div className={`text-th-gray w-screen px-2 lg:px-5 2xl:px-14 flex justify-between xl:justify-around items-center flex-wrap xl:flex-nowrap gap-0 md:gap-1 xl:gap-0 mx-auto font-th-tertiary text-sm`}>
    <Link to="/">
    <img src={`/assets/img/logo_viaggio.png`} className="w-[105px] md:w-[140px] md:mx-auto h-auto max-h-[35px] lg:max-h-[60px] object-contain" alt="Viaggio Logo" />
    </Link>
    <div className="block xl:hidden pr-2">
    {open ? 
    <div className={` text-th-gray`} onClick={() => setOpen(prev => !prev)}>
    <IoCloseSharp size={25} />
    </div>
    :
    <div className={` text-th-gray`} onClick={() => setOpen(prev => !prev)}>
    <RiMenu5Fill size={25} />
    </div>
  }
    </div>
    <div className={`${open ? 'translate-x-0 min-h-screen': 'translate-x-[1000px] xl:translate-x-0 min-h-0'} w-screen h-0 xl:h-auto xl:w-9/12 transition-all duration-500`}>
      <ul className="text-th-gray font-th-tertiary h-screen xl:h-auto justify-start md:justify-center space-y-3 xl:space-y-0 xl:justify-between flex flex-col items-center p-0 lg:p-4 mt-12 xl:flex-row space-x-0 lg:space-x-2 xl:space-x-5 2xl:space-x-10 md:mt-0">
        <li>
          <Link to="/" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap uppercase">Home</Link>
        </li>
        <li>
          <Link to="/hotels" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase">Hotels</Link>
        </li>
        <li>
          <Link to="/dining" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase">Dining</Link>
        </li>
        <li>
          <Link to="/bars" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase">Bars</Link>
        </li>
        <li>
          <Link to='/golf' className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase">Golf</Link>
        </li>
        <li>
        <Link to='/brewing' className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase ">Brewing</Link>
        </li>
        <li>
        <Link to="/liquor_stores" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase ">Liquor Stores</Link>
        </li>
        <li>
          <Link to="/giftcards" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap uppercase ">Gift Cards</Link>
        </li>
        <li>
          <Link to="/contact" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap">CONTACT</Link>
        </li>
        <li className="xl:hidden block py-2">
        <a href="/careers" target="_blank" className={`text-th-white bg-th-gray px-8 py-2 rounded-full cursor-pointer hover:text-th-gray hover:bg-th-yellow transition-all`}>CAREERS</a>
        </li>
      </ul>
    </div>
      <a href="/careers" target="_blank" className={`text-th-white bg-th-gray hidden xl:block px-10 py-2 rounded-full cursor-pointer hover:text-th-gray hover:bg-th-yellow transition-all`}>CAREERS</a>
  </div>
</nav>
  );
};

export default Nav;
import React from 'react'

const Venues = ({title, venues}) => {
  return (
    <section>
    <h3 className='font-th-secondary text-center text-xl tracking-wide mb-1 xl:mb-5'>{title}</h3>
    <div className='flex items-center justify-center gap-3 lg:gap-20 flex-wrap'>
        { 
            venues.map((venue, i) => (
              venue.logo && <a key={i} href={venue.url} className="hover:-translate-y-2 hover:bg-white/40 rounded-lg transition-all" target="_blank" rel="noreferrer"><img src={venue.logo} className="w-[100px] xl:w-[150px] h-[80px] max-h-[80px] object-contain" alt="logo" /></a>
            ))
        }
    </div>
</section>
  )
}

export default Venues
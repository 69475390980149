import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import GiftCards from './pages/GiftCards';
import Dinings from './pages/Dinings';
import Hotels from './pages/Hotels';
import Bars from './pages/Bars';
import Golf from './pages/Golf';
import Brewing from './pages/Brewing';
import LiquioStores from './pages/LiquorStores';
import Contact from './pages/Contact';
import GiftCardCheck from './pages/GiftCardCheck';
import GiftCardReload from './pages/GiftCardReload';
import GiftCardBuy from './pages/GiftCardBuy';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <ScrollToTop />
  <Routes>
  <Route path="/" element={<App />} />
  <Route path="hotels" element={<Hotels />} />
  <Route path="dining" element={<Dinings />} />
  <Route path="bars" element={<Bars />} />
  <Route path="golf" element={<Golf />} />
  <Route path="brewing" element={<Brewing />} />
  <Route path="liquor_stores" element={<LiquioStores />} />
  <Route path="giftcards" element={<GiftCards />} />
  <Route path="giftcards/check" element={<GiftCardCheck />} />
  <Route path="giftcards/buy" element={<GiftCardBuy />} />
  <Route path="giftcards/reload" element={<GiftCardReload />} />
  <Route path="contact" element={<Contact />} />
  </Routes>
  </BrowserRouter>
);

reportWebVitals();
